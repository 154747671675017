import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../../shared/reducers';
import { RouteComponentProps } from 'react-router';
import { RouteConfigComponentProps } from 'react-router-config';
import { push } from 'connected-react-router';
import { Button, Icon } from 'antd';
import { IconArrowsAltHSolid, IconEdit, IconHourglassSolid, IconInfoSolid, IconSyncSolid } from '../../../../components/icons';
import { FormValue, RoundButton } from '../../../../components';
import { LocalizationEnum, localize } from '../../../../localization';
import currencyName from '../../../../shared/util/currencyName';
import { BusinessAccountPreferencesEditModal } from './businessAccountPreferencesEditModal';
import { crewModuleEnabledSelector, getBusinessAccountPreferences } from '../../../../shared/reducers/businessAccountPreferences.reducer';
import { convertMinutesToHoursMinString } from '../../../../shared/util/getDateString';
import './businessAccountPreferencesModule.less';
import {getShiftCountRoundingTypeByCode, getContractValidationTypeByCode} from '../../../../shared/util/utils4';
import {ContractValidationTypeCodeEnum, serverApi} from '../../../../server';
import { showConfirm } from '../../../../components/confirm/showConfirm';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { showNotification } from '../../../../components/notification/showNotification';
import { isDefined } from '../../../../shared/util/utils';
import { canViewFinancialData } from '../../../../shared/util/permissionUtils';
import {
    defaultTaxCalculationTypeTitle,
    IncludeTaxInExternalExpenseSumEnum,
    includeTaxInExternalExpenseSumTitle
} from './businessAccountPreferencesEditModalFieldsData';
import { SettingsPageWrapper } from '../settingsPageWrapper';
import { barcodeTypesMap } from '../documentTemplates/documentTemplatesModuleData';
import { SystemIcon, SystemIconType } from '../../../../components/v2/systemIcon/systemIcon';
import { BarcodePopover } from '../../../../components/barcodePopover/barcodePopover';
import { paymentMethodRequirementTypeNamesMap } from '../paymentMethods/data/paymentMethodsData';
import { legalCountriesNamesMap } from '../../legalDetails/data/legalDetailsData';

interface IProps extends StateProps, DispatchProps, RouteComponentProps, RouteConfigComponentProps, WrappedComponentProps {}

interface IState {}

const secToTime = (sec: number | undefined) => {
    if (isDefined(sec)) {
        let h = Math.floor(Math.abs(sec) / 60);
        let m: string = '' + (Math.abs(sec) - h * 60);
        if (m.length === 1) m = '0' + m;
        return `${sec > 0 ? '+' : sec === 0 ? '' : '-'}${h}:${m}`;
    } else return undefined;
};

class _BusinessAccountPreferencesModule extends React.PureComponent<IProps, IState> {
    componentDidMount(): void {
        this.props.getBusinessAccountPreferences(this.props.businessAccountId);
    }

    onSetCountButtonClick = () => {
        this.props.push(`/${this.props.businessAccountId}/settings/basic/edit`);
    };

    renderSettingsItem = (label, icon, value, help, control?: any) => {
        return (
            <div style={{ display: 'flex' }} className={'rr-page-settings-settings-item'}>
                <div className={'rr-form-item'} style={{ width: 450, minWidth: 450 }}>
                    <div className={'rr-label'}>{label}</div>
                    <div className="rr-value">
                        {icon}
                        <FormValue value={value} emptyText={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__UNSPECIFIED)} />
                    </div>
                </div>
                <div style={{ width: 66, minWidth: 66 }}>{control}</div>
                <div className={'rr-settings-page__info-block'} style={{ display: 'flex' }}>
                    <div>
                        <Icon component={IconInfoSolid} className="rr-settings-page__info-block__icon" />
                    </div>
                    <div>{help}</div>
                </div>
            </div>
        );
    };

    render() {

        return (
            <SettingsPageWrapper
                title={localize(LocalizationEnum.PAGE__BASIC__BASIC_SETTINGS)}
                extra={
                    <RoundButton colorScheme={'default'} onClick={this.onSetCountButtonClick}>
                        <Icon component={IconEdit} />
                        {localize(LocalizationEnum.ASPECT__ACTION__EDIT, 'span')}
                    </RoundButton>
                }
            >
                {this.props.entity ? (
                    <>
                        {this.renderSettingsItem(
                            'Страна',
                            undefined,
                            legalCountriesNamesMap[this.props.entity.legalCountry],
                            localize(LocalizationEnum.PAGE__BASIC__LEGAL_COUNTRY__HELP)
                        )}
                        {this.props.canViewFinancialData &&
                            this.renderSettingsItem(
                                localize(LocalizationEnum.ASPECT__FIELDS__COMMON__CURRENCY),
                                undefined,
                                currencyName(this.props.entity.baseCurrency),
                                localize(LocalizationEnum.PAGE__BASIC__BASE_CURRENCY__HELP)
                            )}
                        {this.renderSettingsItem(
                            'Часовой пояс',
                            undefined,
                            secToTime(this.props.entity.timezoneOffsetUTC),
                            localize(LocalizationEnum.PAGE__BASIC__TIMEZONE_OFFSET__HELP)
                        )}
                        {this.renderSettingsItem(
                            'Длительность смены',
                            <Icon
                                component={IconHourglassSolid}
                                style={{
                                    fontSize: 20,
                                    marginRight: 8,
                                    marginTop: 2,
                                    marginLeft: -4,
                                    verticalAlign: 'top',
                                    color: '#00afee',
                                }}
                            />,
                            convertMinutesToHoursMinString(this.props.entity.shiftLengthInMinutes || 0),
                            localize(LocalizationEnum.PAGE__BASIC__SHIFT_LENGTH__HELP)
                        )}
                        {this.renderSettingsItem(
                            'Округление смен',
                            undefined,
                            getShiftCountRoundingTypeByCode(this.props.entity.shiftCountRoundingType),
                            localize(LocalizationEnum.PAGE__BASIC__SHIFT_COUNT_ROUNDING__HELP)
                        )}
                        {this.renderSettingsItem(
                            'Резерв времени между обязательствами',
                            <Icon
                                component={IconArrowsAltHSolid}
                                style={{
                                    fontSize: 22,
                                    marginRight: 12,
                                    marginTop: 1,
                                    verticalAlign: 'top',
                                    color: '#8d5bae',
                                }}
                            />,
                            convertMinutesToHoursMinString(this.props.entity.requiredTimeIndentBetweenElementsInMinutes || 0),
                            localize(LocalizationEnum.PAGE__BASIC__REQUIRED_TIME_INDENT_BETWEEN_ELEMENTS__HELP)
                        )}
                        {this.renderSettingsItem(
                            'Параллельные операции',
                            undefined,
                            this.props.entity.allowConcurrentOperations ? 'Разрешены' : 'Запрещены',
                            localize(LocalizationEnum.PAGE__BASIC__ALLOW_CONCURRENT_OPERATIONS__HELP)
                        )}
                        {this.props.canViewFinancialData &&
                            this.renderSettingsItem(
                                'Включать бронь в стоимость',
                                undefined,
                                this.props.entity.includeBookedElementsInProjectSum ? 'Да' : 'Нет',
                                localize(LocalizationEnum.PAGE__BASIC__INCLUDE_BOOKED_ELEMENTS_IN_PROJECT_SUM__HELP)
                            )}
                        {this.props.crewModuleEnabled &&
                            this.renderSettingsItem(
                                'Включать запланированные работы в стоимость',
                                undefined,
                                this.props.entity.includePlannedWorkPlanningsInProjectSum ? 'Да' : 'Нет',
                                localize(LocalizationEnum.PAGE__BASIC__INCLUDE_PLANNED_WORK_PLANNINGS_IN_PROJECT_SUM__HELP)
                            )}
                        {this.props.canViewFinancialData &&
                            this.renderSettingsItem(
                                'Контроль актуальности договора',
                                undefined,
                                getContractValidationTypeByCode(this.props.entity?.contractValidationType),
                                localize(LocalizationEnum.PAGE__BASIC__CONTRACT_VALIDATION_TYPE__HELP)
                            )
                        }
                        {this.renderSettingsItem(
                            'Налог по умолчанию',
                            undefined,
                            isDefined(this.props.entity.defaultTaxRate) ? `${this.props.entity.defaultTaxRate}%` : undefined,
                            localize(LocalizationEnum.PAGE__BASIC__DEFAULT_TAX_RATE__HELP)
                        )}
                        {this.renderSettingsItem(
                            'Тип расчёта налога по умолчанию',
                            undefined,
                            isDefined(this.props.entity.taxBaseType)
                                ? defaultTaxCalculationTypeTitle[this.props.entity.taxBaseType]
                                : undefined,
                            localize(LocalizationEnum.PAGE__BASIC__TAX_BASE_TYPE__HELP)
                        )}
                        {this.renderSettingsItem(
                            'Учет налога во внешней стоимости затрат',
                            undefined,
                            includeTaxInExternalExpenseSumTitle[this.props.entity.includeTaxInExternalExpenseSum ? IncludeTaxInExternalExpenseSumEnum.YES : IncludeTaxInExternalExpenseSumEnum.NO],
                            localize(LocalizationEnum.PAGE__BASIC__INCLUDE_TAX_IN_EXTERNAL_EXPENSE_SUM__HELP),
                        )}

                        {this.renderSettingsItem(
                            'Обязательность способа оплаты в платежах',
                            undefined,
                            paymentMethodRequirementTypeNamesMap[this.props.entity.paymentMethodRequirementType],
                            'Настройка добавляет требования в каких случаях должен быть выбран способ оплаты у платежа. Ограничения применяются в формах создания и  редактирования платежей, а также при смене их статусов.'
                        )}

                        {this.renderSettingsItem(
                            'Тип отображения штрихового кода',
                            undefined,
                            <>
                                {barcodeTypesMap[this.props.entity.barcodeType]}
                                {
                                    <BarcodePopover barcodeString={'ABCDE-12345_abcde'}>
                                        <span className={'rr-settings-page__barcode-preview-link'}>Превью</span>
                                    </BarcodePopover>
                                }
                            </>,
                            'Формат отображения строки штрихового кода в графическом виде, используется при генерации всех этикеток в компании. При печати проверяйте, что ваш сканер и принтер подходят для выбранного формата, шаблона этикетки и длины кодов, особенно Code-128 (он больше подходит для коротких цифровых кодов)'
                        )}

                        {this.renderSettingsItem(
                            'Шаблон этикетки',
                            this.props.entity.defaultBadgeTemplate ? (
                                <SystemIcon
                                    type={this.props.entity.defaultBadgeTemplate.isCommon ? SystemIconType.standard : SystemIconType.custom}
                                    style={{ marginRight: 10 }}
                                />
                            ) : null,
                            this.props.entity.defaultBadgeTemplate?.name,
                            'Шаблон используется при генерации всех этикеток в компании, пока он не выбран, генерация этикеток недоступна. Вы можете выбрать подходящий шаблон из стандартных, либо обратиться в поддержку для разработки шаблона на заказ по вашим требованиям'
                        )}

                        {this.renderSettingsItem(
                            'Токен доступа к витрине',
                            undefined,
                            this.props.entity.storefrontAccessToken,
                            'Токен, используемый для получения доступа к АПИ для взаимодействия со стороны модуля витрины вашего интернет магазина. При обновлении токена, доступ по старому токену не предоставляется',
                            <>
                                <Button
                                    className={'rr-settings-page__token-reset-button'}
                                    onClick={async () => {
                                        let yes: boolean | undefined;
                                        if (this.props.entity?.storefrontAccessToken)
                                            yes = await showConfirm(
                                                this.props.intl,
                                                'Вы уверены, что хотите сбросить старый токен доступа и сгенерировать новый?'
                                            );
                                        else yes = true;
                                        if (yes) {
                                            let result;
                                            try {
                                                result = await serverApi.generateStorefrontAccessToken(this.props.businessAccountId);
                                            } catch (e) {
                                                showNotification('error', 'Ошибка генерации токена');
                                            }
                                            if (result?.data) {
                                                await this.props.getBusinessAccountPreferences(this.props.businessAccountId, true);
                                                showNotification('success', 'Токен сгенерирован');
                                            }
                                        }
                                    }}
                                >
                                    <Icon component={IconSyncSolid} />
                                </Button>
                            </>
                        )}
                        {this.renderSettingsItem(
                            'Токен доступа к календарю iCal',
                            undefined,
                            this.props.entity.calendarAccessToken,
                            localize(LocalizationEnum.PAGE__BASIC__CALENDAR_ACCESS_TOKEN__HELP),
                            <>
                                <Button
                                    className={'rr-settings-page__token-reset-button'}
                                    onClick={async () => {
                                        let yes: boolean | undefined;
                                        if (this.props.entity?.calendarAccessToken)
                                            yes = await showConfirm(
                                                this.props.intl,
                                                'Вы уверены, что хотите сбросить старый токен доступа и сгенерировать новый?'
                                            );
                                        else yes = true;
                                        if (yes) {
                                            let result;
                                            try {
                                                result = await serverApi.generateCalendarAccessToken(this.props.businessAccountId);
                                            } catch (e) {
                                                showNotification('error', 'Ошибка генерации токена');
                                            }
                                            if (result?.data) {
                                                await this.props.getBusinessAccountPreferences(this.props.businessAccountId, true);
                                                showNotification('success', 'Токен сгенерирован');
                                            }
                                        }
                                    }}
                                >
                                    <Icon component={IconSyncSolid} />
                                </Button>
                            </>
                        )}

                        {this.props.editCountMode ? (
                            <BusinessAccountPreferencesEditModal
                                title={localize(LocalizationEnum.PAGE__BASIC__EDITING_BASE_SETTINGS)}
                                initialValues={this.props.entity}
                            />
                        ) : null}
                    </>
                ) : null}
            </SettingsPageWrapper>
        );
    }
}

const mapStateToProps = (storeState: IRootState) => {
    return {
        businessAccountId: storeState.system.businessAccountId,
        entity: storeState.businessAccountPreferences.preferences,
        editCountMode: !!(
            storeState.businessAccount.entity &&
            storeState.router.location.pathname === `/${storeState.system.businessAccountId}/settings/basic/edit`
        ),
        canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
        crewModuleEnabled: crewModuleEnabledSelector(storeState),
    };
};

const mapDispatchToProps = { push, getBusinessAccountPreferences };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export const BusinessAccountPreferencesModule = injectIntl(connect(mapStateToProps, mapDispatchToProps)(_BusinessAccountPreferencesModule));
