import { Button, Icon, Popover } from 'antd';
import classNames from 'classnames';
import { IconCheck, IconEdit, IconTrash } from '../../../../../../components/icons';
import AdditionalEntityActions from '../../../../../../components/additionalEntityActions/additionalEntityActions';
import { CustomFieldSelect } from '../../../../../../components/v2/select/customFieldSelect/customFieldSelect';
import { CustomFieldDataTypeCodeEnum, CustomFieldRecord, EntityTypeCodeEnum, KitRecord } from '../../../../../../server';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DynamicForm } from '../../../../../../components';
import {
    customFieldObjToFormFieldObj,
    findFieldGroupIndexById,
    findFieldInGroupsById,
    getCustomFieldIndexByFieldId,
    getCustomFieldValuesFromForm,
} from '../../../../../../shared/util/customFieldsUtils';
import { useAppDispatch } from '../../../../../../store/hooks';
import { changeCustomFieldsBindings } from '../../../../settings/customFields/reducers/customFields.reducer';
import { FormFieldsGroup } from '../../../../../../components/dynamicForm/DynamicForm';
import moment, { Moment } from 'moment';
import { kitsApi } from '../../api/kits.api';
import { useCustomFieldValues } from '../../../products/components/AdditionalEntityActionForCustomFields/hooks/useCustomFieldValues';
import { LocalizationEnum, localize } from '../../../../../../localization';

interface Props {
    selectedRecords: KitRecord[];
    clearSelection: () => void;
}

const valuesToString = (values: string[] | Moment[]) => {
    return values.map((v: string | Moment) => (moment.isMoment(v) ? (v as Moment).toISOString().split('.')[0] + 'Z' : v));
};

const EditActionForCustomFields = ({ selectedRecords, clearSelection }: Props) => {
    const [fields, setFields] = useState<CustomFieldRecord[]>([]);
    const [formData, setFormData] = useState<any>();
    const [formFields, setFormFields] = useState<FormFieldsGroup[]>([]);

    const newValues = useMemo(() => {
        return (formData ? getCustomFieldValuesFromForm(Object.values(formData)[0]) : []) as string[];
    }, [formData]);

    const { notEmptyLength, notEqualLength } = useCustomFieldValues(
        selectedRecords,
        { id: fields[0]?.id, type: fields[0]?.typeCode },
        valuesToString(newValues)
    );

    useEffect(() => {
        if (fields.length) {
            setFormFields([
                {
                    fields: [
                        {
                            ...customFieldObjToFormFieldObj(
                                {
                                    customFieldId: fields[0].id,
                                    name: fields[0].shortName,
                                    typeCode: fields[0].typeCode,
                                    customFieldIndexKey: '0',
                                    financial: fields[0].financial,
                                    multipleValues: fields[0].multipleValues,
                                    processingType: fields[0].processingType,
                                    standard: fields[0].standard,
                                },
                                ''
                            ),
                            customFormItemClassName:
                                fields[0].typeCode === CustomFieldDataTypeCodeEnum.BOOLEAN ? 'custom-checkbox' : undefined,
                        },
                    ],
                },
            ]);
        }
    }, [fields]);

    const fieldAddHandler = useCallback(
        (fieldName: string, index: number) => {
            let fFields = [...formFields];
            let baseField = findFieldInGroupsById(fFields, `${fieldName}[${index}]`);
            let groupIndex = findFieldGroupIndexById(fFields, `${fieldName}[${index}]`);
            if (baseField !== null && groupIndex !== null) {
                baseField = { ...baseField, values: [], defaultValue: undefined };

                let maxId = Math.max(
                    ...formFields[groupIndex].fields
                        .filter((f) => {
                            return f.id.includes(fieldName);
                        })
                        .map((f, index) => {
                            return getCustomFieldIndexByFieldId(f.id) || 0;
                        })
                );

                baseField.id = `${fieldName}[${maxId + 1}]`;

                let filteredFields = formFields[groupIndex].fields.filter((f) => f.id.indexOf(fieldName) === 0);
                if (filteredFields) {
                    formFields[groupIndex].fields.splice(
                        formFields[groupIndex].fields.indexOf(filteredFields[filteredFields.length - 1]) + 1,
                        0,
                        baseField
                    );
                }

                setFormFields(fFields);
            }
        },
        [formFields]
    );

    const fieldRemoveHandler = useCallback(
        (fieldName: string, index: number) => {
            let fFields = [...formFields];
            let groupIndex = findFieldGroupIndexById(fFields, `${fieldName}[${index}]`);

            if (groupIndex !== null) {
                let removeFrom = fFields[groupIndex].fields.findIndex((f) => {
                    return f.id === `${fieldName}[${index}]`;
                });
                if (removeFrom > -1) {
                    fFields[groupIndex].fields.splice(removeFrom, 1);
                    setFormFields(fFields);
                }
            }
        },
        [formFields]
    );

    const dispatch = useAppDispatch();

    const chagneFields = (isCleaning: boolean) => {
        if (fields.length) {
            dispatch(
                changeCustomFieldsBindings({
                    createLinks: true,
                    customFieldBulkLinkObj: {
                        entityTypeCode: EntityTypeCodeEnum.KIT,
                        customFieldsIds: fields.map((f) => f.id),
                        entityIds: [...new Set(selectedRecords.map((r) => r.id))],
                        values: isCleaning ? [] : newValues,
                    },
                    successCallback: () => {
                        dispatch(kitsApi.util?.invalidateTags(['KitsList', 'KitsAvailableFiltersList']));
                        clearSelection();
                    },
                })
            );
        }
    };

    return (
        <Popover
            overlayClassName={classNames('grid-additional-actions-popover', 'rr-grid-actions-popover-nested')}
            autoAdjustOverflow
            placement="bottomLeft"
            trigger="click"
            align={{ offset: [12, 0] }}
            content={
                <>
                    <div className={'rr-grid-actions-popover-header1'}></div>
                    <div className={'rr-grid-actions-popover-content'}>
                        <div className={'category-select-block'}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <span>Поле характеристики</span>
                            </div>
                            <CustomFieldSelect
                                placeholder={'Выберите поле'}
                                openOnMount={true}
                                onDataChange={(data) => setFields(data ?? [])}
                            />
                            {!!fields.length && (
                                <div className="rr-grid-actions-popover-custom-edit-value">
                                    <DynamicForm
                                        addField={fieldAddHandler}
                                        removeField={fieldRemoveHandler}
                                        data={formFields}
                                        onChange={setFormData}
                                    />
                                </div>
                            )}
                        </div>
                        {!!fields.length && (
                            <div className={'category-action-block'}>
                                <Button
                                    className={'action green'}
                                    block
                                    onClick={() => chagneFields(false)}
                                    disabled={!formData || notEqualLength === 0}
                                >
                                    <Icon className={'icon green'} component={IconCheck} />
                                    <span>Задать характеристику</span>
                                    {formData && notEqualLength > 0 ? (
                                        <span className={'categories-count'}>
                                            ({notEqualLength}{' '}
                                            {localize(LocalizationEnum.ASPECT__PLURAL__KIT, undefined, {
                                                value: notEqualLength,
                                            })}
                                            )
                                        </span>
                                    ) : undefined}
                                </Button>
                                <Button className={'action red'} block onClick={() => chagneFields(true)} disabled={notEmptyLength === 0}>
                                    <Icon className={'icon red'} component={IconTrash} />
                                    <span>Очистить характеристику</span>
                                    {notEmptyLength > 0 ? (
                                        <span className={'categories-count'}>
                                            ({notEmptyLength}{' '}
                                            {localize(LocalizationEnum.ASPECT__PLURAL__KIT, undefined, {
                                                value: notEmptyLength,
                                            })}
                                            )
                                        </span>
                                    ) : undefined}
                                </Button>
                            </div>
                        )}
                    </div>
                </>
            }
        >
            <Button className={'grid-additional-actions-button'} block>
                <Icon component={IconEdit} className={'edit-custom-fields-button'} />
                <AdditionalEntityActions content={<></>}>
                    <span>Изменить характеристики</span>
                </AdditionalEntityActions>
            </Button>
        </Popover>
    );
};
export default EditActionForCustomFields;
