import {LocalizationEnum} from '../../../../../../localization';
import {FormFieldsGroup, FormItemType} from '../../../../../../components/dynamicForm/DynamicForm';
import {FormUtils} from '../../../../../../core/utils/formUtils';
import {PageURLParamDescriptionObject, PageURLParamDescriptions} from "../../../../../../core/utils/descriptions";
import {CalendarDisplayType} from "../../../components/Calendar/utils/data/calendarConstants";
import {CalendarCapture, CalendarGridParams} from "../../../components/Calendar/types/params";
import {SummaryScheduleCalendarSortValue} from "../data/sortData";
import {CalendarCategoriesSelect} from "../../../components/CalendarCategoriesSelect";

export interface SummaryScheduleCalendarFilters extends CalendarGridParams<SummaryScheduleCalendarSortValue> {
    screenLeft?: number;
    screenRight?: number;
    group?: SummaryScheduleCalendarSortValue;
    capture?: CalendarCapture;
    search?: string;
    categoryIds?: number[]|number;
    includeRentElements?: boolean;
}

export const summaryScheduleCalendarFiltersDescription: Required<PageURLParamDescriptionObject<SummaryScheduleCalendarFilters>> = {
    screenLeft: PageURLParamDescriptions.numberParam,
    screenRight: PageURLParamDescriptions.numberParam,
    group: PageURLParamDescriptions.searchParam(),
    capture: PageURLParamDescriptions.searchParam(),
    search: PageURLParamDescriptions.searchParam(),
    categoryIds: PageURLParamDescriptions.numbersSelectParam,
    displayType: PageURLParamDescriptions.enumSelectParam(CalendarDisplayType),
    includeRentElements: PageURLParamDescriptions.switchParam
};

export const summaryScheduleCalendarFilters: FormFieldsGroup[] = FormUtils.typedFormFields<SummaryScheduleCalendarFilters>([
    {
        fields: [
            {
                label: '',
                placeholder: LocalizationEnum.ASPECT__FILTERS__SEARCH_FIELD_PLACEHOLDER,
                id: 'search',
                type: FormItemType.Search,
                className: 'rr-input-gray',
            },
            {
                label: LocalizationEnum.ASPECT__FIELDS__COMMON__CATEGORIES,
                id: 'categoryIds',
                type: FormItemType.Component,
                component: CalendarCategoriesSelect,
                placeholder: 'Любые',
                style: { minWidth: 120 },
                className: 'rr-select-gray',
            },
            {
                label: 'Показывать обязательства',
                id: 'includeRentElements',
                type: FormItemType.Switch,
            },
        ],
    },
]);
