import React, { CSSProperties, ReactNode, UIEvent, useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Select } from 'antd';
import { OptionProps } from 'antd/lib/select';
import { LocalizationEnum, useLocalize } from '../../../core/hooks/useLocalize';
import { Spin } from '../../index';
import { useDebounce } from '../../../core/hooks/useDebounce';
import classNames from 'classnames';
import { isDefined } from '../../../shared/util/utils';
import { LabeledValue } from 'antd/es/select';
import './baseSelect.less';
import { showNotification } from '../../notification/showNotification';
import { localizeIntl } from '../../../localization';
import { IntlShape } from 'react-intl';

const limit = 100;

export type BaseSelectValue = string | string[] | number | number[];

export interface BaseSelectBaseProps<RecordType> {
    disabled?: boolean;
    placeholder?: ReactNode;
    value?: BaseSelectValue;
    onChange?: (value: BaseSelectValue | undefined) => void;
    onChangeLabeled?: (value: LabeledValue[] | LabeledValue | undefined) => void;
    multiple?: boolean;
    allowClear?: boolean;
    maxTagCount?: number;
    getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
    onDataChange?: (data: RecordType[] | undefined) => void;
    style?: CSSProperties;
    dropdownStyle?: CSSProperties;
    className?: string;
    showSearch?: boolean; // В мульти режиме всегда показывается поиск
    refetchOnError?: boolean;
    formLabel?: string;
    intl?: IntlShape;
    defaultValue?: LabeledValue[] | LabeledValue;
    openOnMount?: boolean;
}

export type BaseSelectOptionProps = Omit<OptionProps, 'value'>;
// children - отображает значение в списке

export interface BaseSelectProps<RecordType, InfoReadType = RecordType> extends BaseSelectBaseProps<RecordType> {
    fetchInfoReadById?: (id: BaseSelectValue) => Promise<Array<InfoReadType>>;
    fetchRecords: (startFrom: number, limit: number, search?: string) => Promise<Array<RecordType>>;
    fetchRecordsById: (id: BaseSelectValue) => Promise<Array<RecordType>>;
    fetchEntityErrorCallback?: (id: number) => void;
    getRecordId: (record: RecordType) => string | number;
    getOptionProps?: (record: RecordType) => BaseSelectOptionProps;
}

export const selectValueToNumber = (value: string | number | LabeledValue): number => {
    if (typeof value === 'object' && 'key' in value) return +value.key;
    return +value;
};

export const selectValueToString = (value: string | number | LabeledValue): string => {
    if (typeof value === 'object' && 'key' in value) return '' + value.key;
    return '' + value;
};

export const BaseSelect = <T, RT = T>({
    multiple,
    showSearch,
    getRecordId,
    refetchOnError = true,
    openOnMount,
    ...props
}: BaseSelectProps<T, RT>) => {
    const L = useLocalize();
    const [records, setRecords] = useState<T[]>([]);
    const [selectedRecords, setSelectedRecords] = useState<T[]>([]);
    const [fetching, setFetching] = useState(false);
    const [fetchingError, setFetchingError] = useState<any>(null);
    const [initialDataLoading, setInitialDataLoading] = useState(false);
    const [searchString, setSearchString] = useState<string | undefined>();
    const debouncedSearchString = useDebounce<string | undefined>(searchString, 200);
    const [value, setValue] = useState<LabeledValue[] | undefined>();
    const [isInitiated, setIsInitiated] = useState(false);
    const mounted = useRef(false);
    const selectRef = useRef<Select<LabeledValue | LabeledValue[] | undefined>>(null);

    const disableInput = (disable: boolean) => {
        const inputEl = selectRef?.current?.['rcSelect'].inputRef;
        if (inputEl) {
            inputEl.setAttribute('maxlength', disable ? '0' : '255');
        }
    };

    useEffect(() => {
        if (mounted.current && openOnMount) {
            setTimeout(() => {
                const node = ReactDOM.findDOMNode(selectRef.current);
                if (node != null) {
                    const event = new MouseEvent('click', { bubbles: true });
                    node.dispatchEvent(event);
                }
            }, 300);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mounted.current, selectRef.current, openOnMount]);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (!isInitiated) setIsInitiated(true);
    }, [isInitiated]);

    useEffect(() => {
        if (multiple) disableInput(isDefined(showSearch) && !showSearch);
    }, [multiple, showSearch]);

    /* Первоначальная загрузка и при смене значения извне */
    useEffect(() => {
        const v = props.value;
        if (v !== undefined && (!Array.isArray(v) || (Array.isArray(v) && v.length > 0))) {
            const newV = Array.isArray(v) ? v : [v];

            if (!newV.every((id) => value?.find((li) => li.key === id))) {
                setInitialDataLoading(true);
                _fetchRecordsById(v);
            }
        } else {
            setValue(undefined);
        }
    }, [props.value]);

    const _fetchRecordsById = (value: BaseSelectValue) => {
        if (mounted.current) {
            window.setTimeout(() => {
                props
                    .fetchRecordsById(value)
                    .then((data) => {
                        const newValue: any[] = [];
                        data.forEach((item) => {
                            const id = getRecordId(item);
                            const optionProps = getRecordOptionProps(item);
                            newValue.push({ key: id, label: optionProps.label });
                        });
                        setValue(newValue);
                        setInitialDataLoading(false);
                        if (props.onDataChange) props.onDataChange(multiple ? data : [data[0]]); // TODO
                        setSelectedRecords(multiple ? data : [data[0]]);
                        if (data.length === 0 && props.fetchEntityErrorCallback) {
                            const id = Number(Array.isArray(value) ? value[0] : value);

                            showNotification(
                                'error',
                                <span>
                                    Не удалось установить фильтр{' '}
                                    {props.formLabel && props.intl ? (
                                        <span>"{localizeIntl(props.intl, props.formLabel as LocalizationEnum)}" </span>
                                    ) : (
                                        ''
                                    )}
                                    - сущность не найдена по идентификатору
                                </span>
                            );
                            props.fetchEntityErrorCallback(id);
                        }
                    })
                    .catch((e) => {
                        if (refetchOnError) {
                            window.setTimeout(() => {
                                _fetchRecordsById(value);
                            }, 5000);
                        }
                    });
            }, 0);
        }
    };

    // Поиск при изменении debouncedSearchString, так не будет загружать данные в самом начале
    useEffect(() => {
        if (isInitiated) fetchRecords(0, debouncedSearchString);
    }, [debouncedSearchString]);

    const onDropdownVisibleChange = (open: boolean) => {
        if (open) {
            // При открытии загружаем данные
            setRecords([]);
            fetchRecords(0, searchString);
        } else {
            // При закрытии очищаем поиск
            setSearchString(undefined);
        }
    };

    const onChange = useCallback(
        (value: LabeledValue[] | LabeledValue | undefined) => {
            const tmpValue = value ? (Array.isArray(value) ? [...value] : [value]) : undefined;
            const keys = value ? (Array.isArray(value) ? value.map((v) => v.key) : value.key) : undefined;

            const _selectedRecords: T[] = [];
            if (tmpValue) {
                tmpValue.forEach((v) => {
                    let record = records.find((item) => getRecordId(item) === v.key);
                    if (!record) record = selectedRecords.find((item) => getRecordId(item) === v.key);
                    if (record) _selectedRecords.push(record);
                });
            }
            setValue(tmpValue);
            props.onChange?.(keys);
            props.onChangeLabeled?.(value);
            props.onDataChange?.(_selectedRecords);
            setSelectedRecords(_selectedRecords);
        },
        [getRecordId, props, records, selectedRecords]
    );

    const getRecordOptionProps = (record: T): BaseSelectOptionProps => {
        return (
            props.getOptionProps?.(record) || {
                label: record['shortName'],
            }
        );
    };

    const onPopupScroll = (e: UIEvent) => {
        e.persist();
        let { target } = e;
        if (target['scrollTop'] + target['offsetHeight'] === target['scrollHeight']) {
            let startFrom = Math.floor(records.length / limit) * limit;
            fetchRecords(startFrom, searchString);
        }
    };

    const onSearch = (search: string) => {
        setSearchString(search || undefined);
    };

    const fetchRecords = (startFrom: number, search?: string) => {
        setFetching(true);
        setFetchingError(null);
        props
            .fetchRecords(startFrom, limit, search)
            .then((data) => {
                if (startFrom === 0) {
                    setRecords(data);
                } else {
                    const newRecords = [...records];
                    data.forEach((item) => {
                        const index = newRecords.findIndex((rec) => rec['id'] === item['id']);
                        if (index > -1) newRecords[index] = item;
                        else newRecords.push(item);
                    });
                    setRecords(newRecords);
                }
                setFetching(false);
            })
            .catch((e) => {
                setFetchingError(e);
                setFetching(false);
            });
    };

    const recordsToRender = [...records];

    useEffect(() => {
        if (props.defaultValue) onChange(props.defaultValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Select<LabeledValue[] | LabeledValue | undefined>
            ref={selectRef}
            labelInValue
            style={props.style}
            className={classNames('rr-select', props.className, multiple && !showSearch && 'rr-select-multiple-search-off')}
            dropdownClassName={'rr-select-dropdown'}
            dropdownStyle={props.dropdownStyle}
            value={value ?? props.defaultValue}
            onDropdownVisibleChange={onDropdownVisibleChange}
            defaultActiveFirstOption={false}
            placeholder={props.placeholder}
            onChange={onChange}
            getInputElement={() => <input maxLength={255} />}
            mode={multiple ? 'multiple' : 'default'}
            showArrow={!multiple}
            notFoundContent={fetching || fetchingError ? null : L(LocalizationEnum.ASPECT__DATA_PRESENCE__DATA_NOT_FOUND)}
            disabled={props.disabled}
            onPopupScroll={onPopupScroll}
            loading={initialDataLoading}
            allowClear={props.allowClear !== undefined ? props.allowClear : true}
            dropdownMatchSelectWidth={false}
            maxTagCount={props.maxTagCount}
            showSearch={showSearch !== undefined ? showSearch : true}
            autoClearSearchValue={false}
            getPopupContainer={props.getPopupContainer}
            filterOption={false}
            onSearch={onSearch}
            dropdownRender={(menu) => (
                <>
                    {menu}
                    {(fetching || fetchingError || true) && (
                        <div className={'rr-select-info'}>
                            {/* Загрузка */}
                            {fetching && !fetchingError ? (
                                <Spin
                                    className={'rr-select-loading'}
                                    size={'small'}
                                    delay={200}
                                    tip={L(LocalizationEnum.ASPECT__DATA_PRESENCE__DATA_LOADING)}
                                />
                            ) : null}
                            {/* Ошибка */}
                            {!fetching && fetchingError ? (
                                <div className={'rr-select-error'}>{L(LocalizationEnum.ASPECT__DATA_PRESENCE__DATA_LOADING_ERROR)}</div>
                            ) : null}
                        </div>
                    )}
                </>
            )}
        >
            {recordsToRender.map((item, index) => {
                const id = getRecordId(item);
                const optionProps = getRecordOptionProps(item);
                return (
                    <Select.Option
                        title={optionProps?.title}
                        value={id}
                        key={id}
                        disabled={optionProps?.disabled}
                        className={optionProps?.className}
                        style={optionProps?.style}
                        label={optionProps?.label}
                    >
                        {optionProps?.children || optionProps?.label}
                    </Select.Option>
                );
            })}
        </Select>
    );
};

// Когда задано value  и этого нет в списке, то показывается label тот что в label записан
// Когда есть в списке, то выводиться children
