import {SummaryScheduleDataInstance, SummaryScheduleDataNomenclature} from "../api/summaryScheduleCalendarApi";
import {SummaryScheduleCalendarSortValue} from "./sortData";
import {CalendarDisplayType} from "../../../components/Calendar/utils/data/calendarConstants";
import {CalendarLineUtils} from "../../../components/CalendarItemRenderer/utils/calendarLineUtils";
import {AccessibilityMapCalendarGroup} from "../types/accessibilityMapCalendarGroupType";
import {
    getAccessibilityMapCalendarDataFromBase64
} from "../../accessibilityMap/utils/getAccessibilityMapCalendarDataFromBase64";
import {
    NestedRentElementRecord,
    RentElementRecord,
    RentStateCodeEnum,
    TimetableTypeCodeEnum
} from "../../../../../../server";
import moment from "moment";
import {
    AccessibilityMapCalendarItem,
    CalendarItem,
    ElementsCalendarItem
} from "../../../components/Calendar/types/items";
import {
    isGiveawayDelayProblemForNestedRentElement,
    isGiveawayDelayProblemForRentElement
} from "../../elements/utils/checks/isGiveawayDelayProblemForRentElement";

interface processCalendarDataParams {
    data: SummaryScheduleDataNomenclature[];
    sortBy?: SummaryScheduleCalendarSortValue;
    includeRentElements?: boolean;
    showInstancesAccessibilityWhenIncludeRentElements?: boolean;
    displayType: CalendarDisplayType;
    screenLeft: number;
    screenRight: number;
    collapsed: string[];
    openedKits: string[];
}

export const processCalendarData = (params: processCalendarDataParams) => {
    let groupId = 0;
    const groups: AccessibilityMapCalendarGroup[] = [];
    const items: CalendarItem<AccessibilityMapCalendarItem|ElementsCalendarItem>[] = [];
    const groupHeight = CalendarLineUtils.map.groupHeightFromDisplayType.medium[params.displayType];

    params.data.forEach((item, index) => {
        const key = getNomenclatureKey(item);
        let collapsed = !params.collapsed.includes(key);
        let collapsible = Boolean(!params.includeRentElements && item.instanceTracked && item.instances) || Boolean(params.includeRentElements);
        let collapseDisable = Boolean(!params.includeRentElements && item.instanceTracked && item.instances && item.instances.length === 0) || Boolean(params.includeRentElements && item.rentElements && item.rentElements.length === 0);
        if(params.includeRentElements && item.instances && item.instances.length > 0) collapseDisable = false;
        if(collapsible && collapseDisable) collapsed = false;
        groups.push(itemToGroup(item, groupId, groupHeight, collapsible, collapsed, collapseDisable));
        availableMapToItems(item.availableMap, groupId, key, params.screenLeft, params.screenRight).forEach(item => {
            items.push(item);
        });

        if(params.includeRentElements){
            if(item.rentElements && item.rentElements.length > 0){
                if(!collapsed){
                    const isAnonymous = item.instanceTracked;
                    const itemKey = getNomenclatureKey(item);
                    item.rentElements.forEach((rentElement, index)=>{
                        groupId += 1;
                        groups.push(groupForBulk(groupId, groupHeight, isAnonymous && index === 0 ? 'Анонимные' : undefined));
                        if(!rentElement.kitId){
                            const _item = rentElementToItem(itemKey + '_' + rentElement.id, rentElement, undefined, groupId, params.screenLeft, params.screenRight, false, false);
                            items.push(_item);
                        }else{
                            const groupIsKit = Boolean(item.kitId);
                            if(groupIsKit){
                                const kitOpened = Boolean(rentElement.kitId && params.openedKits.includes(itemKey + '_' + rentElement.id));
                                const _item = rentElementToItem(itemKey + '_' + rentElement.id, rentElement, undefined, groupId, params.screenLeft, params.screenRight, kitOpened, groupIsKit)
                                items.push(_item);
                                if(kitOpened){
                                    rentElement.nestedRentElements?.forEach((nestedRentElement)=>{
                                        groupId += 1;
                                        groups.push(groupForBulk(groupId, groupHeight));
                                        const item = rentElementToItem(itemKey + '_' + rentElement.id + '_' + nestedRentElement.id, rentElement, nestedRentElement, groupId, params.screenLeft, params.screenRight, false, groupIsKit);
                                        items.push(item);
                                    });
                                }
                            }else{
                                const _item = rentElementToItem(itemKey + '_' + rentElement.id, rentElement, rentElement.nestedRentElements?.[0], groupId, params.screenLeft, params.screenRight, false, false)
                                items.push(_item);
                            }
                        }
                    });
                }
            }
        }

        if (item.instances) {
            item.instances.forEach((instanceItem) => {
                if(!collapsed){
                    if(!params.includeRentElements){
                        groupId += 1;
                        groups.push(itemToGroup(instanceItem, groupId, groupHeight, true, false, false));
                        availableMapToItems(instanceItem.availableMap || '', groupId, getNomenclatureKey(instanceItem), params.screenLeft, params.screenRight).forEach(item => {
                            items.push(item);
                        });
                    }else{
                        groupId += 1;
                        groups.push(itemToGroup(instanceItem, groupId, groupHeight, true, false, false));
                        instanceItem.rentElements?.forEach((rentElement, index)=>{
                            if(index !== 0){
                                groupId += 1;
                                groups.push(groupForBulk(groupId, groupHeight));
                            }
                            if(rentElement.kitId && rentElement.nestedRentElements && rentElement.nestedRentElements.length > 0){
                                const item = rentElementToItem(instanceItem.id + '_' + rentElement.id, rentElement, rentElement.nestedRentElements[0], groupId, params.screenLeft, params.screenRight, false, false);
                                items.push(item);
                            }else{
                                const item = rentElementToItem(instanceItem.id + '_' + rentElement.id, rentElement, undefined, groupId, params.screenLeft, params.screenRight, false, false);
                                items.push(item);
                            }

                        });
                    }
                }
            })
        }
        groupId += 1;
    });

    return {
        groups,
        items
    };
};

const itemToGroup = (item: SummaryScheduleDataNomenclature | SummaryScheduleDataInstance, groupId: number, groupHeight: number, collapsible:boolean, collapsed: boolean, collapseDisable: boolean): AccessibilityMapCalendarGroup => {
    if ('id' in item) {
        return {
            id: groupId,
            title: '',
            height: groupHeight,
            productId: item.productId,
            variantId: item.variantId,
            instanceId: item.id,
            instanceName: item.name,
            collapsible: false,
            collapsed: false,
            collapseDisable: false
        };
    } else {
        return {
            id: groupId,
            title: '',
            height: groupHeight,
            productId: item.productId,
            kitId: item.kitId,
            variantId: item.variantId,
            productName: item.productName,
            variantName: item.variantName,
            kitName: item.kitName,
            collapsible: collapsible,
            collapsed,
            collapseDisable: collapseDisable
        };
    }
}

const groupForBulk = (groupId: number, groupHeight: number, extraName?: string): AccessibilityMapCalendarGroup => {
    return {
        id: groupId,
        title: '',
        height: groupHeight,
        collapsible: false,
        collapsed: false,
        collapseDisable: false,
        extraName: extraName
    };
}

const availableMapToItems = (map: string, groupId: number, nomenclatureKey: string, screenLeft: number, screenRight: number): CalendarItem<AccessibilityMapCalendarItem|ElementsCalendarItem>[] => {
    const data: any[] = [];
    const itemsData = getAccessibilityMapCalendarDataFromBase64([{
        typeCode: TimetableTypeCodeEnum.AVAILABLE,
        mapString: map
    }]);
    itemsData.forEach((itemData) => {
        const {typeCode, value, startTime, endTime} = itemData;

        if ((startTime < screenLeft && endTime < screenLeft) || (startTime > screenRight && endTime > screenRight)) return;

        data.push({
            id: `${nomenclatureKey}_${value}_${endTime}`,
            elementType: 'accessibility',
            group: groupId,
            typeCode,
            value: Number(value),
            startTime: +startTime,
            endTime: +endTime,
            start_time: moment(Math.max(+startTime, screenLeft || 0)),
            end_time: moment(Math.min(+endTime, screenRight || 0)),
        });
    });
    return data;
}

const rentElementToItem = (id:string, rentElement:RentElementRecord, nestedRentElementRecord:NestedRentElementRecord|undefined, groupId: number,  screenLeft: number, screenRight: number, collapsed: boolean, groupIsKit: boolean): CalendarItem<ElementsCalendarItem> => {

    const isSubrent =  rentElement.subrentId;
    const rentPeriodStartDate = moment(nestedRentElementRecord?.rentTerms.rentPeriodStartDate || rentElement.commonFields.rentTerms.rentPeriodStartDate);
    const rentPeriodEndDate = moment(nestedRentElementRecord?.rentTerms.rentPeriodEndDate || rentElement.commonFields.rentTerms.rentPeriodEndDate);

    const _startTime =
        +rentPeriodStartDate < screenLeft
            ? moment(screenLeft)
            : rentPeriodStartDate;

    const isDelay = nestedRentElementRecord ? isGiveawayDelayProblemForNestedRentElement(nestedRentElementRecord) : isGiveawayDelayProblemForRentElement(rentElement);

    let _endTime = isDelay
        ? moment(Math.min(screenRight, Math.max(+rentPeriodEndDate, +moment())))
        : +rentPeriodEndDate > screenRight
            ? moment(screenRight)
            : rentPeriodEndDate;

    const instanceCount = nestedRentElementRecord?.instanceCount || rentElement.commonFields.instanceCount;
    const stateCode = nestedRentElementRecord?.stateCode || rentElement.commonFields.stateCode;
    const variantName = nestedRentElementRecord?.variantName || rentElement.commonFields.variantName;
    const numberInActivityFrame = nestedRentElementRecord?.numberInActivityFrame || rentElement.commonFields.numberInActivityFrame;
    const rentTerms = nestedRentElementRecord?.rentTerms || rentElement.commonFields.rentTerms;
    const problemsAndWarnings = nestedRentElementRecord?.problemsAndWarnings || rentElement.commonFields.problemsAndWarnings;

    let projectName:string|undefined;
    if(rentElement.projectId){
        projectName = rentElement.projectShortName;
    }else if(rentElement.subrentId){
        projectName = rentElement.subrentShortName;
    }else if(rentElement.templateId){
        projectName = rentElement.templateShortName;
    }

    const data:CalendarItem<ElementsCalendarItem> = nestedRentElementRecord ? {
        id,
        title: nestedRentElementRecord.nomenclatureShortName,
        elementType: isSubrent ? 'subrent' : 'rent',
        group: groupId,
        start_time: _startTime,
        end_time: _endTime,
        instanceCount,
        isDraft: stateCode === RentStateCodeEnum.DRAFT || stateCode === RentStateCodeEnum.SUBRENTDRAFT,
        isCollapsed: collapsed,
        kitId: undefined,
        stateCode: stateCode,
        variantName: variantName,
        numberInActivityFrame: numberInActivityFrame,
        rentTerms: rentTerms,
        problemsAndWarnings: problemsAndWarnings,
        record: nestedRentElementRecord,
        parentRecord: rentElement,
        isKitChild: true,
        summaryScheduleData: !groupIsKit ? {
            projectName,
            instanceCount,
            kitName: rentElement.commonFields.nomenclatureShortName
        } : undefined
    } : {
        id,
        title: rentElement.commonFields.nomenclatureShortName,
        elementType: isSubrent ? 'subrent' : 'rent',
        group: groupId,
        start_time: _startTime,
        end_time: _endTime,
        instanceCount,
        isDraft: stateCode === RentStateCodeEnum.DRAFT || stateCode === RentStateCodeEnum.SUBRENTDRAFT,
        isCollapsed: collapsed,
        kitId: rentElement.kitId,
        stateCode: stateCode,
        variantName: variantName,
        numberInActivityFrame: numberInActivityFrame,
        rentTerms: rentTerms,
        problemsAndWarnings: problemsAndWarnings,
        record: rentElement,
        parentRecord: undefined,
        isKitChild: false,
        summaryScheduleData: {
            projectName,
            instanceCount
        }
    };
    return data;
}

export const getNomenclatureKey = (item: SummaryScheduleDataNomenclature | SummaryScheduleDataInstance): string => {
    let id = '';
    if ('id' in item) {
        id = 'I' + item.id;
    } else {
        if (item.kitId) id = 'K' + item.kitId;
        else if (item.variantId) id = 'V' + item.variantId;
        else if (item.productId) id = 'P' + item.productId;
    }
    return id;
}

interface CalendarNomenclatureItem {
    productId?: number;
    variantId?: number;
    kitId?: number;
    instanceId?: number;
}

export const getNomenclatureCollapsedKey = (item: CalendarNomenclatureItem): string => {
    let id = '';
    if (item.instanceId) id = 'I' + item.instanceId;
    if (item.kitId) id = 'K' + item.kitId;
    else if (item.variantId) id = 'V' + item.variantId;
    else if (item.productId) id = 'P' + item.productId;
    return id;
}
